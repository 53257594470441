<template>
  <div>
    <v-toolbar
      elevation="0"     
    >
    <v-icon large style="margin-right:10px;">{{ icon }}</v-icon>
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
    </v-toolbar>
    <v-progress-linear indeterminate v-if="loading" />
    <v-container v-else-if="editedItem">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.Name"
            :label="$t('content.name', locale)"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <upload-audio-file
            style="width: 100%"
            @change="handleChangeAudioFile"
            :oldValue="editedItem.FileName ? {
                          name: editedItem.FileName,
                          size: editedItem.FileSize
                        } : null"
          />
        </v-col>
        <v-col
          v-if="contentTypeData.BySequence"
          cols="12"
          sm="12"
          md="12"
        >
        <center>
          <v-btn-toggle
            color="primary"
            v-model="editedItem.Sequence"
            borderless
          >
            <v-btn value="all">
              <span class="hidden-sm-and-down">all</span>
            </v-btn>

            <v-btn value="5">
              <span class="hidden-sm-and-down">5 min</span>
            </v-btn>

            <v-btn value="10">
              <span class="hidden-sm-and-down">10 min</span>
            </v-btn>

            <v-btn value="20">
              <span class="hidden-sm-and-down">20 min</span>
            </v-btn>
          </v-btn-toggle>
        </center>
        </v-col>
      </v-row>
      <v-row style="padding: 10px;">
        <v-select
          v-model="editedItem.Hide"
          itemValue="id"
          itemText="name"
          dense
          label="Estado"
          hide-details
          :items="[{
            id: 0,
            name: 'Visible'
          },
          {
            id: 1,
            name: 'Oculto'
          }]"
          outlined
        />
      </v-row>
      <v-row>
        <availability
          v-model="editedItem.Availability"
          :locale="locale"
          @change="handleChangeAvailability"
        />
      </v-row>
    </v-container>
    <action-buttons
      v-if="editedItem"
      :id="editedItem.ID"
      :onDeleteItem="handleDelete"
      :onSaveItem="handleSave"
      :onClose="handleClose"
      :locale="locale"
    />
  </div>
</template>
<script>
  const defaultItem = {
    Name: '',
    Sequence: 'all',
    IsTemp: false,
    Availability: null,
  }
  
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import ActionButtons from '@/components/ActionButtons'
  import Availability from '@/components/Availability'
  import UploadAudioFile from '@/components/uploadAudioFile/Index'

  export default{
    name: 'AudioForm',
    components: {
      ActionButtons,
      Availability,
      UploadAudioFile,
    },
    props: {
      title: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      },
      contentTypeData: {
        type: Object,
        required: true
      },
      editedID: {
        type: String,
        default: null
      },
      onClose: {
        type: Function,
        required: true
      },
      onDelete: {
        type: Function,
        required: true
      }
    },
    data () {
      return {
        loading: false,
        editedItem: null
      }
    },
    computed: {
      ...mapState('app',['locale']),
    },
    watch: {
      editedID () {
        this.handleGetData()
      },
    },
    mounted () {
      this.handleGetData()
    },
    methods: {
      handleGetData () {
        this.loading = true
        if (!this.editedID) {
          this.editedItem = JSON.parse(JSON.stringify(defaultItem))
          this.editedItem.ContentTypeDataID = this.contentTypeDataID
          this.loading = false
        } else {
          api.getItem ('dviewcontent', `private/audios/${this.contentTypeData.ID}/`, this.editedID)
          .then(response => {   
            if (response) response.Hide = response.Hide ? 1 : 0
            this.editedItem = response
            this.loading = false
          })
        }
      },
      handleSave () {
        if (this.editedItem.ID)
          api.updateItem ('dviewcontent', `private/audios/${this.contentTypeData.ID}/`, this.editedItem.ID, this.editedItem)
            .then(() => {   
              this.onClose()
            })
        else 
          api.addItem ('dviewcontent', `private/audios/${this.contentTypeData.ID}`, this.editedItem)
            .then(() => {   
              this.onClose()
            })
      },
      handleDelete () {
        this.onDelete(this.editedID)
      },
      handleClose () {
        this.onClose()
      },
      handleChangeAvailability (v) {
        this.editedItem.Availability = v
      },
      handleChangeAudioFile (v) {
        const aux = v.name.split('.')
        v.ext =  aux.length > 1 ? aux[1] : 'mp3'
        this.editedItem.newMedia = v
      },
    }
  }
</script>
